define("dashboard-talent/controllers/talent/experience", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    country: "",
    country_short: "",
    lat: "",
    lng: "",
    actions: {
      didUpdatePlace: function (place) {
        var country, country_short;
        this.set('address', place.place.formatted_address);
        this.set('lng', place.lng);
        this.set('lat', place.lat);
        place.place.address_components.forEach(function (element) {
          if (element.types[0] == "country") {
            country = element.long_name;
            country_short = element.short_name;
          }
        });
        this.set('country', country);
        this.set('country_short', country_short);
      },
      invalidUserSelection: function () {},
      experience() {}
    }
  });
});