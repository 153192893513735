define('dashboard-talent/components/common/my-applications', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    didRender() {
      this._super(...arguments);
      if (window.$('.tab-pane.fade.active.in:first').length < 1) {
        window.$('.tab-pane.fade:first').addClass('active in');
      }
    }
  });
});