define('dashboard-talent/components/common/non-organic-talent-modal', ['exports', 'dashboard-talent/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    currentUser: Ember.inject.service(),
    autoLoad: true,
    modelType: 'pending-application',
    pendingApplications: null,
    didRender() {
      this._super(...arguments);
      this.set('pendingApplications', this.get('objects'));
    },
    actions: {
      loaded() {
        var self = this;
        if (!this.get('currentUser.isCollectTalent') && this.get('currentUser.showModal') && this.get('objects').length == 1) {
          window.$('#nonOrganicModal').modal();

          this.get('store').queryRecord('tour', {}).then(response => {
            self.set('page_tour', response);
            if (self.get('page_tour.guided_tour') == false) {
              self.get('page_tour').set('guided_tour', true);
              self.get('page_tour').save();
            }
          });
        }
      }
    }
  });
});