define('dashboard-talent/adapters/application', ['exports', 'dashboard-talent/adapters/drf', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _drf, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',
    addTrailingSlashes: false,
    // there are certain queries we'll want to intercept
    // so that we can change the url while still using
    // the meber store
    query: function (store, type) {

      switch (type.modelName) {
        case 'feed-item':
          return this.feedItem(...arguments);
        case 'channel':
          return this.talentFollowing(...arguments);
        case 'draft-application':
          return this.talentDraftApplication(...arguments);
        case 'pending-application':
          return this.talentPendingApplication(...arguments);
        case 'application':
          return this.talentApplication(...arguments);
        case 'submitted-application':
          return this.talentSubmittedApplication(...arguments);
        case 'processed-application':
          return this.talentProcessedApplication(...arguments);
        case 'shortlisted-application':
          return this.talentShortlistedApplication(...arguments);
        case 'job':
          return this.talentSavedJob(...arguments);
        case 'featured-job':
          return this.talentFeaturedJob(...arguments);
        case 'company':
          return this.talentFollowingCompany(...arguments);
        case 'interest':
          return this.talentInterest(...arguments);
        case 'location-interest':
          return this.talentLocationInterest(...arguments);
        case 'education':
          return this.talentEducation(...arguments);
        case 'experience':
          return this.talentExperience(...arguments);
        case 'message':
          return this.messageList(...arguments);
        case 'conversation':
          return this.conversationList(...arguments);
        case 'job-type':
          return this.jobTypes(...arguments);
        case 'job-level':
          return this.jobLevels(...arguments);
        case 'skill':
          return this.talentSkill(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    queryRecord: function (store, type) {
      switch (type.modelName) {
        case 'talent-notification':
          return this.loadTalentNotifications(...arguments);
        case 'notification':
          return this.loadNotificationBadges(...arguments);
        case 'talentresponse':
          return this.loadTalent(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    // same as above but for patching a single record
    updateRecord: function (store, type) {
      switch (type.modelName) {
        case 'message':
          return this.updateMessage(...arguments);
        case 'education':
          return this.updateEducation(...arguments);
        case 'experience':
          return this.updateExperience(...arguments);
        case 'preference':
          return this.updatePreference(...arguments);
        case 'tour':
          return this.updateTour(...arguments);
        case 'post':
          return this.updatePostType(...arguments);
        case 'talent-notification':
          return this.updateTalentNotifications(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    deleteRecord: function (store, type) {
      switch (type.modelName) {
        case 'education':
          return this.removeEducation(...arguments);
        case 'experience':
          return this.removeExperience(...arguments);
        case 'company':
          return this.removeTalentFollowing(...arguments);
        case 'channel':
          return this.removeTalentFollowing(...arguments);
        case 'interest':
          return this.removeTalentInterest(...arguments);
        case 'location-interest':
          return this.removeTalentLocationInterest(...arguments);
        case 'skill':
          return this.removeSkill(...arguments);
        case 'draft-application':
          return this.removeDraftApplication(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    createRecord: function (store, type) {
      switch (type.modelName) {
        case 'education':
          return this.addEducation(...arguments);
        case 'experience':
          return this.addExperience(...arguments);
        case 'message':
          return this.addMessage(...arguments);
        case 'interest':
          return this.addInterest(...arguments);
        case 'location-interest':
          return this.addLocationInterest(...arguments);
        case 'introduction':
          return this.saveIntroduction(...arguments);
        case 'skills':
          return this.saveSkills(...arguments);
        case 'password':
          return this.savePassword(...arguments);
        case 'bio':
          return this.saveBio(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    feedItem(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/feed";
      return this.ajax(url, 'GET', { data: query });
    },
    talentFollowing(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/following";
      return this.ajax(url, 'GET', { data: query });
    },
    talentApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/applications";
      return this.ajax(url, 'GET', { data: query });
    },
    talentDraftApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/applications?status=draft";
      return this.ajax(url, 'GET', { data: query });
    },
    talentPendingApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/applications?status=pending";
      return this.ajax(url, 'GET', { cache: false, data: query });
    },
    talentSubmittedApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/applications?status=submitted";
      return this.ajax(url, 'GET', { data: query });
    },
    talentProcessedApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/applications?status=processed";
      return this.ajax(url, 'GET', { data: query });
    },
    talentShortlistedApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/applications?status=shortlisted";
      return this.ajax(url, 'GET', { data: query });
    },
    talentSavedJob(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/";
      return this.ajax(url, 'GET', { data: query });
    },
    talentFeaturedJob(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs";
      return this.ajax(url, 'GET', { data: query });
    },
    jobTypes(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/jobtypes";
      return this.ajax(url, 'GET', { data: query });
    },
    jobLevels(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/filters";
      return this.ajax(url, 'GET', { data: query });
    },
    talentFollowingCompany(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/following";
      return this.ajax(url, 'GET', { data: query });
    },
    talentInterest(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/interests";
      return this.ajax(url, 'GET', { data: query });
    },
    addInterest() {
      var data = this.getData(...arguments);
      var formData = {
        id: data.job_type_id,
        job_level: data.job_level
      };
      var url = this.get('host') + this.get('namespace') + "/talent/interests";
      var res = this.ajax(url, 'POST', { data: formData }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    talentLocationInterest(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/interests/locations";
      return this.ajax(url, 'GET', { data: query });
    },
    addLocationInterest() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/talent/interests/locations";
      var res = this.ajax(url, 'POST', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    talentEducation(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/education";
      return this.ajax(url, 'GET', { data: query });
    },
    talentExperience(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/experience";
      return this.ajax(url, 'GET', { data: query });
    },
    talentSkill(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/talent/skills";
      return this.ajax(url, 'GET', { cache: false, data: query });
    },
    loadTalent(store, type, id) {
      var url = this.get('host') + this.get('namespace') + "/users/" + id;
      return this.ajax(url, 'GET');
    },
    loadTalentNotifications() {
      var url = this.get('host') + this.get('namespace') + "/talent/notifications/settings";
      return this.ajax(url, 'GET');
    },
    loadNotificationBadges() {
      var url = this.get('host') + this.get('namespace') + "/talent/notifications";
      return this.ajax(url, 'GET', { cache: false });
    },
    messageList(store, type, query) {
      var conversation_id = query.conversation_id;
      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages";
      return this.ajax(url, 'GET', { data: query });
    },
    getData(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return data;
    },
    updateMessage(store, type, snapshot) {
      var data = this.getData(...arguments);
      var conversation_id = data.conversation_id;
      var message_id = snapshot.id;

      // only want to send over read as being the changed property
      // if we sent the text property api would assume we want to patch it
      delete data.text;

      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages/" + message_id;
      var res = this.ajax(url, 'PATCH', { data: data }).then().catch(() => {
        return null;
      });
      return res;
    },
    updateTalentNotifications() {
      var data = this.getData(...arguments);

      var url = this.get('host') + this.get('namespace') + "/talent/notifications/settings";
      return this.ajax(url, 'PATCH', { data: data });
    },
    addEducation() {
      var data = this.getData(...arguments);
      var location_keys = ['address', 'city', 'state', 'post_code', 'country', 'country_short', 'latitude', 'longitude'];
      location_keys.forEach(key => {
        if (!data[key]) delete data[key];
      });
      var url = this.get('host') + this.get('namespace') + "/talent/education";
      return this.ajax(url, 'POST', { data: data });
    },
    addExperience() {
      var data = this.getData(...arguments);
      var location_keys = ['address', 'city', 'state', 'post_code', 'country', 'country_short', 'latitude', 'longitude'];
      location_keys.forEach(key => {
        if (!data[key]) delete data[key];
      });
      var url = this.get('host') + this.get('namespace') + "/talent/experience";
      return this.ajax(url, 'POST', { data: data });
    },
    addMessage() {
      var data = this.getData(...arguments);
      var conversation_id = data.conversation_id;
      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages";
      return this.ajax(url, 'POST', { data: data });
    },
    conversationList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/conversations";
      return this.ajax(url, 'GET', { data: query });
    },
    updateEducation(store, type, snapshot) {
      var data = this.getData(...arguments);
      delete data['location'];
      var location_keys = ['address', 'city', 'state', 'post_code', 'country', 'country_short', 'latitude', 'longitude'];
      location_keys.forEach(key => {
        if (!data[key]) delete data[key];
      });
      var url = this.get('host') + this.get('namespace') + "/talent/education/" + snapshot.id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateExperience(store, type, snapshot) {
      var data = this.getData(...arguments);
      delete data['location'];
      var location_keys = ['address', 'city', 'state', 'post_code', 'country', 'country_short', 'latitude', 'longitude'];
      location_keys.forEach(key => {
        if (!data[key]) delete data[key];
      });
      var url = this.get('host') + this.get('namespace') + "/talent/experience/" + snapshot.id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    updatePreference() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/user/emailPreferences";
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateTour() {
      var data = this.getData(...arguments);

      var url = this.get('host') + this.get('namespace') + "/user/tours";
      return this.ajax(url, 'PATCH', { data: data });
    },
    updatePostType() {
      var data = this.getData(...arguments);

      var url = this.get('host') + this.get('namespace') + "/talent/posttypes";
      return this.ajax(url, 'PATCH', { data: data });
    },
    saveIntroduction() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/talent";
      var res = this.ajax(url, 'PATCH', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    saveSkills() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/talent/skills";
      return this.ajax(url, 'PATCH', { data: data });
    },
    savePassword() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/talent";
      var res = this.ajax(url, 'PATCH', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    saveBio() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/talent";
      if (data['email'] == null) delete data['email'];
      var res = this.ajax(url, 'PATCH', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    removeEducation(store, type, snapshot) {
      var data = this.getData(...arguments);
      delete data['location'];
      var url = this.get('host') + this.get('namespace') + "/talent/education/" + snapshot.id;
      return this.ajax(url, 'DELETE', { data: data });
    },
    removeExperience(store, type, snapshot) {
      var data = this.getData(...arguments);
      delete data['location'];
      var url = this.get('host') + this.get('namespace') + "/talent/experience/" + snapshot.id;
      return this.ajax(url, 'DELETE', { data: data });
    },
    removeTalentFollowing() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/talent/following";
      return this.ajax(url, 'DELETE', { data: { channel_id: data.id } });
    },
    removeTalentInterest(store, type, snapshot) {
      var url = this.get('host') + this.get('namespace') + "/talent/interests/" + snapshot.id;
      var res = this.ajax(url, 'DELETE').then(() => {
        return null;
      });
      return res;
    },
    removeTalentLocationInterest(store, type, snapshot) {
      var url = this.get('host') + this.get('namespace') + "/talent/interests/locations/" + snapshot.id;
      var res = this.ajax(url, 'DELETE').then(() => {
        return null;
      });
      return res;
    },
    removeSkill(store, type, snapshot) {
      var url = this.get('host') + this.get('namespace') + "/talent/skills/" + snapshot.id;
      return this.ajax(url, 'DELETE');
    },
    removeDraftApplication(store, type, snapshot) {
      var job_id = snapshot.record.get('job.id');
      var url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/applications";
      return this.ajax(url, 'DELETE');
    }
  });
});