define('dashboard-talent/models/talentresponse', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    //talent: DS.belongsTo("talent"),
    talent: _emberData.default.attr()
  });
});