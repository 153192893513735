define('dashboard-talent/components/common/confirm-popup', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    actions: {
      confirm: function () {
        this.confrim();
      }
    }
  });
});