define('dashboard-talent/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inflector = _emberInflector.default.inflector;

  inflector.irregular('preference', 'user/emailPreferences');
  inflector.irregular('tour', 'user/tours');
  inflector.irregular('post', 'talent/posttypes');
  inflector.irregular('notification', 'talent/notifications');

  exports.default = {};
});