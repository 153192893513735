define('dashboard-talent/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),

    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});