define('dashboard-talent/services/g-map', ['exports', 'ember-cli-g-maps/services/g-map'], function (exports, _gMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gMap.default;
    }
  });
});