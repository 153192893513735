define('dashboard-talent/routes/infinity-scroll', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {
    model() {
      return this.infinityModel('object');
    }
  });
});