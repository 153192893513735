define('dashboard-talent/helpers/substring', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.substring = substring;
  function substring(params /*, hash*/) {
    var string = params[0];
    var start = params[1];
    var end = params[2];
    if (string) {
      return string.substring(start, end);
    }
  }

  exports.default = Ember.Helper.helper(substring);
});