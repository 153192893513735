define('dashboard-talent/components/talent/edit-intro', ['exports', 'dashboard-talent/components/base-component', 'pell'], function (exports, _baseComponent, _pell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    initialIntroduction: null,
    errors: null,
    isUpdated: false,
    options: null,
    optionsPell: null,
    init() {
      this._super(...arguments);
      this.set('errors', {});
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        statusbar: false,
        content_style: ".mce-content-body {font-size:0.9rem;font-family:Roboto, 'Helvetica Neue', sans-serif;}",
        plugins: ['autolink lists link charmap anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime nonbreaking save contextmenu directionality', 'template paste textpattern'],
        toolbar1: 'bold | bullist | link ',
        contextmenu: 'link'
      });
      this.set('optionsPell', {
        actions: [{
          name: 'bold',
          icon: '<span><svg width="24" height="24"><path d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 01-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4z" fill-rule="evenodd"></path></svg></span>',
          result: () => (0, _pell.exec)('bold')
        }, {
          name: 'ulist',
          icon: '<span><svg width="24" height="24"><path d="M11 5h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zM4.5 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1z" fill-rule="evenodd"></path></svg></span>',
          result: () => (0, _pell.exec)('insertUnorderedList')
        }, {
          name: 'link',
          icon: '<span><svg width="24" height="24"><path d="M6.2 12.3a1 1 0 011.4 1.4l-2.1 2a2 2 0 102.7 2.8l4.8-4.8a1 1 0 000-1.4 1 1 0 111.4-1.3 2.9 2.9 0 010 4L9.6 20a3.9 3.9 0 01-5.5-5.5l2-2zm11.6-.6a1 1 0 01-1.4-1.4l2-2a2 2 0 10-2.6-2.8L11 10.3a1 1 0 000 1.4A1 1 0 119.6 13a2.9 2.9 0 010-4L14.4 4a3.9 3.9 0 015.5 5.5l-2 2z" fill-rule="nonzero"></path></svg></span>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }]
      });
      this.set('initialIntroduction', this.get('currentUser.talent.introduction'));
    },
    didRender() {
      this._super(...arguments);
      if (this.get('bioEdited')) {
        this.set('currentUser.talent.completion', this.get('bioEdited'));
      }
      var self = this;
      window.$('.intro .mce-btn:not(.mce-disabled)').on('click touchstart', function () {
        self.set('isUpdated', true);
        window.$('.intro').find('.btn--edit').attr('disabled', false);
      });
    },
    actions: {
      valueChanged: function (val) {
        this.set('currentUser.talent.introduction', val);
        var isUpdated = this.get('initialIntroduction') != this.get('currentUser.talent.introduction');
        this.set('isUpdated', isUpdated);
      },
      saveIntroduction: function () {
        const initialIntroduction = this.get('initialIntroduction');
        var introduction = window.$('.intro iframe').contents().find('#tinymce')[0].innerHTML;
        introduction = introduction == '\n' || introduction == '<p><br data-mce-bogus="1"></p>' ? '' : introduction;
        var intro = { introduction: introduction };
        if (introduction != '') {
          this.set('errors', {});
          this.set('isUpdated', false); //Prevent double clicks
          this.get('store').createRecord('introduction', intro).save().then(() => {
            this.get('currentUser').load();
            this.toast.success('Your introduction has been updated');
          }).catch(xhr => {
            this.set('error', xhr.errors);
          });
          if (introduction && introduction != initialIntroduction && !initialIntroduction) {
            this.set('bioEdited', this.get('currentUser.talent.completion') + 15);
          } else if (!introduction) {
            this.set('bioEdited', this.get('currentUser.talent.completion') - 15);
          }
          this.set('initialIntroduction', introduction);
        } else {
          this.set('errors', {
            'introduction': 'This is a required field!'
          });
        }
      }
    }
  });
});