define('dashboard-talent/router', ['exports', 'dashboard-talent/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('talent', function () {
      this.route('education');
      this.route('experience');
      this.route('skill');
    });
    this.route('applications');
    this.route('edit');
    this.route('bio');
    this.route('infinity-scroll');
    this.route('settings');
  });

  exports.default = Router;
});