define('dashboard-talent/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),

    load() {
      let userId = this.get('session.data.authenticated.talent.user.id');
      if (!Ember.isEmpty(userId)) {
        var self = this;
        this.get('store').queryRecord('tour', {}).then(response => {
          self.set('page_tour', response);
          self.set('showModal', !self.get('page_tour.guided_tour'));
        });
        return this.get('store').queryRecord('talentresponse', userId).then(response => {
          self.set('talent', response.get('talent'));
          self.set('isCollectTalent', self.get('talent.user.source').includes('collect_'));
          self.get('store').query('pending-application', {}).then(response => {
            self.set('isOrganicTalent', self.get('talent.user.source').includes('organic') || self.get('talent.user.source').includes('_job') || response.get('length') == 1);
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});