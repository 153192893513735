define('dashboard-talent/components/talent/edit-bio', ['exports', 'dashboard-talent/components/base-component', 'dashboard-talent/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    saving: false,
    errors: null,
    state: "Bio",
    initial_email: null,
    initial_location: null,
    isUpdated: false,
    avatar: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/front_page_banner.jpg");
    }),
    isValidPhoneNumber: true,
    phone_number: Ember.computed('currentUser.talent.user.{country_code,phone}', function () {
      if (this.get('currentUser.talent.user.phone')) {
        var number = this.get('currentUser.talent.user.phone').replace(/\D/g, '');
        return `${this.get('currentUser.talent.user.country_code')}${number}`;
      } else {
        return this.get('currentUser.talent.user.country_code');
      }
    }),
    init() {
      this._super(...arguments);
      this.set('errors', {});
      this.set('initial_email', this.get('currentUser.talent.user.email'));
      this.set('initial_location', this.get('currentUser.talent.location.address'));
      var self = this;
      this.get('store').query('submitted-application', {}).then(response => {
        if (response.get('length') > 0 || self.get('currentUser.isOrganicTalent') || self.get('currentUser.isCollectTalent')) {
          self.set('state', 'Bio');
        } else {
          self.set('state', 'Application');
        }
      });
      setTimeout(function () {
        self.set('isUpdated', false);
        self.set('errors', {});
      }, 100);
    },
    didRender() {
      // Allow only numbers for phone field
      window.$('.talent-bio-phone-field').on('keypress keyup blur', function (event) {
        if (event.which < 48 || event.which > 57) {
          event.preventDefault();
        }
      });
    },
    actions: {
      update: function () {
        var data = {
          'first_name': this.get('currentUser.talent.user.first_name'),
          'last_name': this.get('currentUser.talent.user.last_name'),
          'email': this.get('currentUser.talent.user.email'),
          'phone': this.get('currentUser.talent.user.phone'),
          'current_title': this.get('currentUser.talent.current_title'),
          'current_company': this.get('currentUser.talent.current_company'),
          'address': this.get('currentUser.talent.location.address'),
          'city': this.get('currentUser.talent.location.city'),
          'state': this.get('currentUser.talent.location.state'),
          'post_code': this.get('currentUser.talent.location.post_code'),
          'longitude': this.get('currentUser.talent.location.longitude'),
          'latitude': this.get('currentUser.talent.location.latitude'),
          'country': this.get('currentUser.talent.location.country'),
          'country_short': this.get('currentUser.talent.location.country_short'),
          'country_code': this.get('currentUser.talent.user.country_code')
        };

        var bio = this.get('store').createRecord('bio', data);
        var emailChanged = false;
        bio.validate().then(({ validations }) => {
          var errors = {};
          if (this.get('errors.location')) {
            errors['location'] = 'This is a required field!';
          }
          if (!this.get('isValidPhoneNumber')) {
            errors['phone'] = 'Please enter a valid phone number!';
          }
          validations.get('errors').forEach(error => {
            if (error.type == 'presence') {
              errors[error.attribute] = 'This is a required field!';
            } else {
              errors[error.attribute] = error.message;
            }
          });
          this.set('errors', errors);
          if (validations.get('isValid') && !this.get('errors.location') && this.get('isValidPhoneNumber')) {
            if (this.get('initial_email') == bio.get('email')) {
              bio.set('email', null);
            } else {
              emailChanged = true;
            }
            bio.save().then(response => {
              this.set('currentUser.talent.completion', response.get('completion'));
              this.set('initial_location', this.get('currentUser.talent.location.address'));
              this.toast.success('Your bio has been updated');
              this.set('isUpdated', false);
              if (emailChanged) {
                this.send('invalidateSession');
              }
            }).catch(xhr => {
              xhr.errors.forEach(() => {
                this.set('errors.email', 'That email address is already in use.');
              });
            });
          }
        });
      },
      didUpdatePlace: function (place) {
        if (typeof place.place == 'undefined') {
          if (place != '' && place != this.get('currentUser.talent.location.address')) {
            this.set('errors.location', 'This is a required field!');
          } else {
            this.set('errors.location', '');
          }
        } else {
          this.set('errors.location', '');
          var country, country_short, city, state, post_code;
          this.set('currentUser.talent.location.address', place.place.formatted_address);
          if (!place.lng) {
            this.set('currentUser.talent.location.longitude', null);
          } else {
            this.set('currentUser.talent.location.longitude', place.lng.toFixed(8));
          }
          if (!place.lat) {
            this.set('currentUser.talent.location.longitude', null);
          } else {
            this.set('currentUser.talent.location.latitude', place.lat.toFixed(8));
          }
          place.place.address_components.forEach(function (element) {
            var type = element.types[0];
            if (type == 'country') {
              country = element.long_name;
              country_short = element.short_name;
            }
            if (type == 'locality' || type == 'postal_town') {
              city = element.long_name;
            }
            if (type == 'administrative_area_level_1') {
              state = element.long_name;
            }
            if (type == 'postal_code') {
              post_code = element.long_name;
            }
          });
          this.set('currentUser.talent.location.country', country);
          this.set('currentUser.talent.location.country_short', country_short);
          this.set('currentUser.talent.location.city', city);
          this.set('currentUser.talent.location.state', state);
          this.set('currentUser.talent.location.post_code', post_code);
          this.set('isUpdated', true);
        }
      },
      invalidUserSelection: function () {},
      invalidateSession: function () {
        return this.get('session').invalidate();
      },
      uploadCV(file) {
        this.set('errors.CV', '');
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          var options = {
            headers: {
              Authorization: headerValue
            },
            contentType: 'multipart/form-data',
            method: 'POST',
            fileKey: 'cv'
          };
          file.upload(_environment.default.APP.API_HOST + '/talent/cv', options).then(() => {
            window.swal({
              text: "Your CV has been uploaded. Don't forget to review the details to make sure everything is perfect.",
              icon: "success",
              buttons: {
                confirm: {
                  text: "Got it, thanks for the tip!",
                  closeModal: true
                }
              },
              dangerMode: true
            }).then(() => {
              window.location.reload(true);
            });
          }).catch(() => {
            this.set('errors.CV', 'Incorrect document type, please upload a pdf or docx file.');
            var self = this;
            Ember.run.later(function () {
              self.set('errors.CV', '');
            }, 2500);
          });
        });
      },
      valueChanged(currentUser, key, value) {
        currentUser.set(`talent.${key}`, value);
        this.set('isUpdated', true);
      },
      phoneChanged(currentUser, number, metadata) {
        var phone = window.$('#edit-bio-phone').val().replace(/[^0-9]/g, '');
        var country_code = window.$('.selected-dial-code').html();
        currentUser.set('talent.user.phone', phone);
        currentUser.set('talent.user.country_code', country_code);
        this.set('isValidPhoneNumber', metadata.isValidNumber);
        this.set('isUpdated', true);
        if (metadata.isValidNumber) {
          this.set('errors.phone', '');
        } else {
          this.set('errors.phone', 'Please enter a valid phone number!');
        }
      }
    }
  });
});