define('dashboard-talent/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr(),
    vpo_id: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),
    featured: _emberData.default.attr("number"),
    views: _emberData.default.attr("number"),
    impressions: _emberData.default.attr("number"),
    talent_pool: _emberData.default.attr("number"),
    new_applications: _emberData.default.attr("number"),
    new_messages: _emberData.default.attr("number"),
    live_jobs: _emberData.default.attr("number"),
    image_banner: _emberData.default.attr("string"),
    image_logo: _emberData.default.attr("string"),
    image_card: _emberData.default.attr("string"),
    jobbions: _emberData.default.attr("number"),
    last_seen: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date"),
    channel: _emberData.default.attr()
  });
});