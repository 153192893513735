define('dashboard-talent/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'dashboard-talent/config/environment'], function (exports, _applicationRouteMixin, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    beforeModel() {
      this._super(...arguments);
      if (!this.get('currentUser.session.data.authenticated.talent')) {
        window.location.href = `https://${_environment.default.APP.DOMAIN}`;
      }
      return this.get('currentUser').load();
    },
    triggerAuthentication: function () {
      window.location.assign(this.get('authenticationRoute'));
    },
    actions: {
      refreshCurrentRoute: function () {
        this.refresh();
      },
      openConversation: function (conversation) {
        this.set('conversation', conversation);
        this.set('messageFormOpened', !this.get('messageFormOpened'));
        // refresh the model so the modals have the correct info
        this.refresh();
      },
      sessionInvalidationSucceeded: function () {
        window.location.assign(`https://${_environment.default.APP.DOMAIN}`);
      }
    },
    model() {
      // this will be empty until the sendMessage action above fills these in
      // this model is only used by the modals so this is not an issue
      var model = {
        conversation: this.get('conversation'),
        messageFormOpened: this.get('messageFormOpened')
      };
      return model;
    }
  });
});