define('dashboard-talent/models/password', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const PasswordValidations = (0, _emberCpValidations.buildValidations)({
        current_password: (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'This is a required field!'
        }),
        password: {
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                message: 'This is a required field!'
            }), (0, _emberCpValidations.validator)('length', {
                min: 6,
                message: 'Password is too short (minimum is 6 characters)'
            }), (0, _emberCpValidations.validator)('format', {
                regex: /^(?=.*[0-9])(?=.*[a-zA-Z])([\S]+)$/,
                message: 'Password must include at least one letter and a number'
            })]
        },
        confirm_password: {
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                message: 'This is a required field!'
            }), (0, _emberCpValidations.validator)('confirmation', {
                on: 'password',
                message: 'Passwords do not match'
            })]
        }
    });

    exports.default = _emberData.default.Model.extend(PasswordValidations, {
        current_password: _emberData.default.attr('string'),
        password: _emberData.default.attr('string'),
        confirm_password: _emberData.default.attr('string'),
        results: _emberData.default.attr()
    });
});