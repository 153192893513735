define('dashboard-talent/components/common/my-application', ['exports', 'dashboard-talent/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    params: Ember.computed('cursor', function () {
      var params = this._super();
      return params;
    })
  });
});