define('dashboard-talent/components/base-component', ['exports', 'dashboard-talent/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        session: Ember.inject.service('session'),
        currentUser: Ember.inject.service(),
        cloudfront: _environment.default.APP.CLOUD_FRONT_HOST,
        homepage: "https://" + _environment.default.APP.DOMAIN
    });
});