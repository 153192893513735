define('dashboard-talent/components/settings/settings-notifications', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    checked: true,
    init() {
      this._super(...arguments);
      this.get('store').queryRecord('talent-notification', {}).then(response => {
        this.set('notification_settings', response);
      });
    },
    actions: {
      updateShortlisted: function (notification_settings) {
        this.toggleProperty('notification_settings.shortlisted');
        notification_settings.save();
      },
      updateNewMessage: function (notification_settings) {
        this.toggleProperty('notification_settings.new_message');
        notification_settings.save();
      },
      updateOppurtunities: function (notification_settings) {
        this.toggleProperty('notification_settings.oppurtunities');
        notification_settings.save();
      }
    }
  });
});