define('dashboard-talent/components/common/company-logo', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    company: null,
    companyLogo: Ember.computed('company', function () {
      if (this.get('company.image_logo')) {
        return this.get('company.image_logo');
      } else if (this.get('company.logo')) {
        return this.get('company.logo');
      }
    }),
    hasLogo: Ember.computed(function () {
      return this.get('company.image_logo') || this.get('company.logo');
    }),
    initials: Ember.computed(function () {
      // gets the initials of the provided name
      // uses only the first 3 found
      var name = this.get('company.name');
      var initials = name.split(" ").map(n => n[0]).join("");
      initials = initials.substring(0, 3);
      return initials;
    }),
    backgroundColor: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      if (this.get('company.brandColor')) {
        return new Ember.String.htmlSafe(`background-color: #${this.get('company.brandColor')}`);
      } else {
        return new Ember.String.htmlSafe(`background-color: #${Math.random().toString(16).slice(2, 8)}`);
      }
    })
  });
});