define('dashboard-talent/components/talent/my-bio', ['exports', 'dashboard-talent/components/base-component', 'dashboard-talent/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    saving: false,
    myStickyOptions: null,
    state: "Bio",
    completion: '',
    store: Ember.inject.service(),
    error: null,
    onBioEdited: Ember.observer('bioEdited', function () {
      this.send('updateCompletion');
    }),
    placeholderAvatar: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/talent_placeholder_avatar.png");
    }),
    bioCompletion: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(`width: ${this.get('currentUser.talent.completion')}%`);
    }),
    init() {
      this._super(...arguments);
      this.set('myStickyOptions', {
        wrapperClassName: '',
        topSpacing: 100,
        bottomSpacing: 300
      });
      this.set('error', {});
      this.set('completion', this.get('currentUser.talent.completion'));
      this.get('store').query('submitted-application', {}).then(response => {
        if (response.get('length') > 0 || this.get('currentUser.isOrganicTalent') || this.get('currentUser.isCollectTalent')) {
          this.set('state', 'Bio');
        } else {
          this.set('state', 'Application');
        }
      });
    },
    actions: {
      setAvatar(talent, file) {
        this.set('saving', true);
        this.set('error.avatar', '');
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          var options = {
            headers: {
              Authorization: headerValue
            },
            contentType: 'multipart/form-data',
            method: 'PATCH',
            fileKey: 'avatar'
          };
          var self = this;
          file.upload(_environment.default.APP.API_HOST + '/talent', options).then(response => {
            this.set('currentUser.talent.user.avatar', response.body.user.avatar);
            Ember.run.later(function () {
              self.set('saving', false);
            }, 500);
            this.toast.success('Your avatar has been updated');
          }).catch(() => {
            self.set('error.avatar', 'Please upload an image file.');
            Ember.run.later(function () {
              self.set('saving', false);
            }, 500);
            Ember.run.later(function () {
              self.set('error.avatar', '');
            }, 2500);
          });
        });
      },
      previewMyBio: function () {
        this.set('previewTalentBio', !this.get('previewTalentBio'));
      },
      updateCompletion: function () {
        this.set('currentUser.talent.completion', this.get('bioEdited'));
        this.set('bioCompletion', new Ember.String.htmlSafe(`width: ${this.get('bioEdited')}%`));
        this.set('completion', this.get('currentUser.talent.completion'));
      }
    }
  });
});