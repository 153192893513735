define('dashboard-talent/components/common/rounded-logo', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    image: null,
    initials: Ember.computed('name', function () {
      // gets the initials of the provided name
      // uses only the first 3 found
      var name = this.get('name');
      var initials = name.split(" ").map(n => n[0]).join("");
      initials = initials.substring(0, 3);
      return initials;
    })
  });
});