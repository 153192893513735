define('dashboard-talent/components/common/draft-banner', ['exports', 'dashboard-talent/components/base-component', 'dashboard-talent/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    link: _environment.default.APP.WEB_HOST,
    submittedApplication: null,
    submissionCount: null,
    init() {
      this._super(...arguments);
      this.get('store').query('submitted-application', {}).then(response => {
        this.set('submissionCount', response.get('length'));
      });
    },
    didInsertElement() {
      window.$('.draft-banner-bottom').hide();
    },
    didRender() {
      if (window.$(window).width() < 767) {
        window.$('.application-page').css('margin-top', `${window.$('.draft-banner')[0].offsetHeight}px`);
      } else {
        window.$('.application-page').css('margin-top', '60px');
      }

      window.$(window).scroll(function () {
        if (window.$('.draft-banner').length) {
          var docViewTop = window.$(window).scrollTop();
          var docViewBottom = docViewTop + window.$(window).height();
          var elemTop = window.$('.draft-banner').offset().top;
          var elemBottom = elemTop + window.$('.draft-banner').height();

          if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            window.$('.draft-banner-bottom').slideUp('slow');
            window.$('.footer-3.bg--primary').css('position', 'unset');
            window.$('.footer-3.bg--primary').css('margin-bottom', 0);
          } else {
            window.$('.draft-banner-bottom').slideDown('slow');
            window.$('.footer-3.bg--primary').css('position', 'relative');
            window.$('.footer-3.bg--primary').css('margin-bottom', window.$('.draft-banner-bottom').outerHeight());
          }
        }
      });

      var docViewTop = window.$(window).scrollTop();
      var docViewBottom = docViewTop + window.$(window).height();

      var elemTop = window.$('.draft-banner').offset().top;
      var elemBottom = elemTop + window.$('.draft-banner').height();

      elemBottom <= docViewBottom && elemTop >= docViewTop;
    },
    actions: {
      submit: function (application) {
        if (this.get('currentUser.talent.completion') < 60) {
          window.$('#jobCompleteBio').modal('show');
        } else {
          var url = `${_environment.default.APP.API_HOST}/jobs/${application.get('job.id')}/applications`;
          var data = {
            'status': 'submitted',
            'answers': {}
          };
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'PATCH',
              data: JSON.stringify(data),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).then(() => {
              if (this.get('submissionCount')) {
                Ember.run(() => {
                  this.set('submittedApplication', application);
                  this.get('pendingApplications').removeObject(application);
                  if (this.get('pendingApplications').length == 0) {
                    window.$('.application-page').css('margin-top', '0');
                  }
                  window.$('#applicationComplete').modal('show');
                });
              } else {
                window.location.href = `https://${_environment.default.APP.DOMAIN}/search?application_submitted=true&company=${application.get('job.company.name')}&type=${application.get('job.type').name}`;
              }
            });
          });
        }
      }
    }
  });
});