define('dashboard-talent/helpers/parse-int', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseInteger = parseInteger;
  function parseInteger(params /*, hash*/) {
    return parseInt(params[0]);
  }

  exports.default = Ember.Helper.helper(parseInteger);
});