define('dashboard-talent/utils/g-maps/math', ['exports', 'ember-cli-g-maps/utils/g-maps/math'], function (exports, _math) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _math.default;
    }
  });
});