define('dashboard-talent/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    tour: Ember.inject.service(),
    beforeModel(transition) {
      this._super(...arguments);
      if (!this.get('session.isAuthenticated')) {
        transition.abort();
      }
    },
    afterModel() {
      // Initialize the currentUser
      this.get('currentUser').load();
    }
  });
});