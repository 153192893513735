define('dashboard-talent/routes/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    phoneInput: Ember.inject.service(),
    beforeModel() {
      return this.get('phoneInput').load();
    },
    afterModel() {
      // Initialize the currentUser
      this.get('currentUser').load();
    }
  });
});