define('dashboard-talent/components/common/my-conversation-list-item', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    hasUnread: Ember.computed('conversation.unread', function () {
      return this.get('conversation.unread') > 0;
    }),
    title: Ember.computed('conversation', function () {
      var title = `${this.get('conversation.initiator.first_name')} ${this.get('conversation.initiator.last_name')}`;
      return title.length > 15 ? `${title.substring(0, 15)}...` : title;
    }),
    image: Ember.computed('conversation', function () {
      var logo = null;
      this.get('conversation.participants').forEach(participant => {
        if (participant.company) {
          logo = participant.company.image_logo;
        }
      });
      return logo;
    }),
    lastMessage: Ember.computed('conversation', function () {
      if (this.get('conversation.last_message') == null) {
        return '';
      }
      return this.get('conversation.last_message').length > 30 ? `${this.get('conversation.last_message').substring(0, 30)}...` : this.get('conversation.last_message');
    })
  });
});