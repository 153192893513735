define("dashboard-talent/initializers/liquid-fire", ["exports", "liquid-fire/velocity-ext"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'liquid-fire',
    initialize: function () {}
  };
});