define('dashboard-talent/components/common/feed-job', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    actions: {
      removeDraft: function (application) {
        application.destroyRecord();
        this.get('applications').removeObject(application);
      }
    }
  });
});