define('dashboard-talent/components/settings/settings-email-preference', ['exports', 'dashboard-talent/components/loader-component'], function (exports, _loaderComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderComponent.default.extend({
    modelType: 'preference',
    preferences: Ember.computed.alias('objects'),
    autoLoad: true,
    actions: {
      emailPreference: function (preference) {
        if (preference.get('subscribed')) {
          preference.set('subscribed', false);
        } else {
          preference.set('subscribed', true);
          window.$('#emailPreference').modal('hide');
          Ember.run.later(function () {
            window.$('#emailPreferenceAlert').modal('show');
          }, 500);
        }
        preference.save();
      }
    }
  });
});