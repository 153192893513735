define('dashboard-talent/components/common/my-chat', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    title: Ember.computed('conversation', function () {
      var currentUserId = this.get('session.data.authenticated.talent.user.id');
      return this.get('conversation') ? this.get('conversation').title(currentUserId) : '';
    }),
    subtitle: Ember.computed('conversation', function () {
      return this.get('conversation.participant.company.name');
    }),
    image: Ember.computed('conversation', function () {
      var logo = null;
      if (this.get('conversation')) {
        this.get('conversation.participants').forEach(participant => {
          if (participant.company) {
            logo = participant.company.image_logo;
          }
        });
      }
      return logo;
    })
  });
});