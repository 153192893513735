define('dashboard-talent/components/common/interest-filter', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    selectedJobType: null,
    selectedJobLevel: null,
    errors: null,
    onControlFeedOpened: Ember.observer('isControlFeedOpened', function () {
      this.set('errors', {});
    }),
    onLocationInterestRemoved: Ember.observer('locationInterestRemoved', function () {
      this.set('errors', {});
      window.$('select[name=location]').val('');
    }),
    init() {
      this._super(...arguments);
      if (this.get('modelType')) {
        this.send('load');
      }
      this.set('errors', {});
    },
    actions: {
      load() {
        this.get('store').query(this.get('modelType'), {}).then(response => {
          this.set(`${this.get('modelType')}s`, response);
        });
      },
      setJobType: function (id) {
        this.set('selectedJobType', id);
      },
      setJobLevel: function (id) {
        this.set('selectedJobLevel', id);
      },
      addLocation: function (name) {
        if (name) {
          this.get('store').query('location-interest', {}).then(response => {
            var interests = response.toArray();
            let exists = interests.filter(interest => interest.get('country_short').toUpperCase() == name);
            if (exists.length) {
              this.set('errors.locationInterest', 'The location added already exists.');
            } else {
              this.set('errors.locationInterest', '');
              var newLocationInterest = this.get('store').createRecord('location-interest', {
                'country_short': name
              });
              newLocationInterest.save().then(() => {
                this.set('locationInterestAdded', !this.get('locationInterestAdded'));
                window.$('select[name=location]').val('');
              });
            }
          });
        } else {
          this.set('errors.locationInterest', '');
        }
      }
    }
  });
});