define("dashboard-talent/models/channel", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    active: _emberData.default.attr("number"),
    featured: _emberData.default.attr("number"),
    company: _emberData.default.attr(),
    companies: _emberData.default.attr(),
    type: _emberData.default.attr("string"),
    views: _emberData.default.attr("number"),
    impressions: _emberData.default.attr("number"),
    banner: _emberData.default.attr("string"),
    logo: _emberData.default.attr("string"),
    card: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date")
  });
});