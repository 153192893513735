define('dashboard-talent/models/experience', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const ExperienceValidations = (0, _emberCpValidations.buildValidations)({
    company: (0, _emberCpValidations.validator)('presence', true),
    title: (0, _emberCpValidations.validator)('presence', true),
    description: (0, _emberCpValidations.validator)('presence', true),
    date_from: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _emberData.default.Model.extend(ExperienceValidations, {
    talent: _emberData.default.belongsTo("talent"),
    company: _emberData.default.attr("string"),
    title: _emberData.default.attr("string"),
    location: _emberData.default.attr(),
    description: _emberData.default.attr("string"),
    date_from: _emberData.default.attr(),
    date_to: _emberData.default.attr(),
    address: _emberData.default.attr("string"),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    post_code: _emberData.default.attr('string'),
    longitude: _emberData.default.attr("string"),
    latitude: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    country_short: _emberData.default.attr("string"),
    errors: _emberData.default.attr()
  });
});