define('dashboard-talent/models/post', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    text: _emberData.default.attr(),
    job: _emberData.default.attr(),
    image: _emberData.default.attr(),
    video: _emberData.default.attr()
  });
});