define('dashboard-talent/serializers/post', ['exports', 'dashboard-talent/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    extractId() {
      return 1;
    }
  });
});