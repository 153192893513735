define("dashboard-talent/models/feed-item", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr("string"),
    type: _emberData.default.attr(),
    job: _emberData.default.attr(),
    content: _emberData.default.attr(),
    company: _emberData.default.attr(""),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date")
  });
});