define('dashboard-talent/components/settings/settings-password', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    onSettingsPasswordOpened: Ember.observer('isSettingsPasswordOpened', function () {
      this.set('errors', {});
      this.set('current_password', '');
      this.set('password', '');
      this.set('confirm_password', '');
    }),
    actions: {
      update_password: function () {
        var data = this.getProperties('current_password', 'password', 'confirm_password');
        var password = this.get('store').createRecord('password', data);
        var self = this;
        password.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          this.set('errors', errors);
          if (validations.get('isValid')) {
            this.set('errors', {});
            password.save().then(() => {
              window.$('#update_password.close').click();
              self.toast.success('Password updated successfully.');
            }).catch(xhr => {
              xhr.errors.forEach(error => {
                if (error.detail == 'Error, Invalid current password provided') {
                  this.set('errors.current_password', 'Invalid current password provided');
                }
              });
            });
          }
        });
      }
    }
  });
});