define('dashboard-talent/components/common/my-chat-list-item', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    // marks unread messages as read
    didRender() {
      this._super(...arguments);
      if (this.get('message.read') == false) {
        this.set('message.read', true);
        this.get('message').save();
        this.get('conversation').decrementProperty('unread');
        this.get('notifications').decrementProperty('new_messages');
      }
    }
  });
});