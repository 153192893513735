define('dashboard-talent/serializers/talent', ['exports', 'dashboard-talent/serializers/drf', 'ember-data'], function (exports, _drf, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      education: { embedded: 'always' },
      experience: { embedded: 'always' },
      user: { embedded: 'always' },
      following: { embedded: 'always' },
      skills: { embedded: 'always' }
    }
  });
});