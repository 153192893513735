define('dashboard-talent/components/talent/edit-education', ['exports', 'dashboard-talent/components/loader-component', 'moment'], function (exports, _loaderComponent, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderComponent.default.extend({
    modelType: 'education',
    educations: Ember.computed.alias('objects'),
    tagName: 'div',
    yearsFrom: null,
    yearsTo: null,
    months: null,
    errors: null,
    autoLoad: true,
    address: '',
    longitude: '',
    latitude: '',
    country: '',
    country_short: '',
    city: '',
    state: '',
    post_code: '',
    addNewEducation: false,
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    initialEducation: null,
    options: null,
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        statusbar: false,
        content_style: ".mce-content-body {font-size:0.9rem;font-family:Roboto, 'Helvetica Neue', sans-serif;}",
        plugins: ['autolink lists link charmap anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime nonbreaking save contextmenu directionality', 'template paste textpattern'],
        toolbar1: 'bold | bullist | link ',
        contextmenu: 'link'
      });

      // Initialize the educations
      this.get('store').unloadAll(this.get('modelType'));
      this.set('loading', false);
      this.set('loaded', false);
      this.set("objects", []);
      this.send('load');

      var today = new Date().getFullYear();
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var yearsFrom = [];
      var yearsTo = [];
      for (var i = today + 6; i >= 1965; i--) {
        yearsTo.push(i);
        if (i <= today) {
          yearsFrom.push(i);
        }
      }
      this.set('months', months);
      this.set('yearsFrom', yearsFrom);
      this.set('yearsTo', yearsTo);
      this.send('clearProperties');
    },
    didRender() {
      this._super(...arguments);
      this.set('initialEducation', this.get('educations'));
      if (this.get('bioEdited')) {
        this.set('currentUser.talent.completion', this.get('bioEdited'));
      }
      window.$('.education-list .mce-btn:not(.mce-disabled)').on('click touchstart', function () {
        window.$(this).closest('.clearfix.col-sm-12').find('.btn--edit').attr('disabled', false);
      });
    },
    replaceAt(string, index, replacement) {
      return `${string.substr(0, index)}${replacement}${string.substr(index + replacement.length)}`;
    },
    actions: {
      updateEducation: function (education, field, type) {
        if (type == undefined) {
          education.set('showSchoolError', true);
          education.set('showQualificationError', true);
          education.set('showGradeError', true);
        }
        education.set('isUpdated', true);

        let prefix = 'location.';
        if (type == 'location') {
          education.set('location', {});
        }
        let locationKeys = ['address', 'city', 'state', 'post_code', 'longitude', 'latitude', 'country', 'country_short'];
        locationKeys.forEach(key => {
          if (type == 'location') {
            education.set(`${prefix}${key}`, education.get(key));
          } else {
            education.set(key, education.get(`${prefix}${key}`));
          }
        });
        var educationIndex = this.get('educations').indexOf(education);
        var description = window.$(`#education${educationIndex} iframe`).contents().find('#tinymce')[0].innerHTML;
        description = description == '\n' || description == '<p><br data-mce-bogus="1"></p>' ? '' : description;
        education.set('description', description);
        var self = this;
        delete education.errors;
        var datesAreCorrect = true;
        education.validate().then(({ validations }) => {
          education.set('errors', {});
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = 'This is a required field!';
          });
          education.set('errors', errors);
          if (education.get('date_from') == '' || education.get('date_from').includes('De')) {
            education.set('errors.date_from', 'This is a required field!');
            datesAreCorrect = false;
          }
          if (education.get('date_to')) {
            if ((0, _moment.default)(education.get('date_to')).isBefore(education.get('date_from'))) {
              education.set('errors.date_to', 'Your completion date cannot be before your starting date');
              datesAreCorrect = false;
            }
            if (education.get('date_to') == '' || education.get('date_to').includes('De')) {
              education.set('errors.date_to', 'This is a required field!');
              datesAreCorrect = false;
            }
          }
          Ember.run.later(function () {
            window.$(`#education${educationIndex} [class*=validation-error]`).each(index => {
              if (window.$(`#education${educationIndex} [class*=validation-error]`)[index].innerText != '') {
                window.$(`#education${educationIndex} [class*=validation-error]`)[index].parentElement.scrollIntoView();
                return false;
              }
            });
          }, 500);
          if (validations.get('isValid') && datesAreCorrect) {
            if (type != 'date' && type != 'location' && type != 'description') {
              education.save().then(function () {
                self.toast.success('Your education has been updated');
                education.set('isUpdated', undefined);
                self.get('currentUser').load();
              });
            }
          }
        });
      },
      cancelNewEducation: function () {
        window.$('.education-add').find('form')[0].reset();
        this.send('clearProperties');
        this.set('addNewEducation', false);
      },
      removeEducation: function () {
        var education = this.get('selectedObj');
        education.destroyRecord();
        this.get('educations').removeObject(education);
        this.toast.success('Education removed');
        if (this.get('educations').length == 0) {
          this.set('bioEdited', this.get('currentUser.talent.completion') - 15);
        }
        this.set('initialEducation', this.get('educations'));
        this.get('currentUser').load();
      },
      removeConfirm: function (education) {
        this.set('selectedObj', education);
      },
      saveEducation: function () {
        var selects = window.$('select[id^=education_date_]');
        var date = {};
        selects.each(function () {
          var name = window.$(this).attr('name');
          var value = window.$(this).val();
          date[name] = value;
        });
        var date_from = `${date['education_date_from_year']}-${date['education_date_from_month']}-01`;
        var date_to = `${date['education_date_to_year']}-${date['education_date_to_month']}-01`;
        var data = this.getProperties('school', 'qualification', 'grade', 'description', 'address', 'city', 'state', 'post_code', 'longitude', 'latitude', 'country', 'country_short');
        data.description = window.$('.education-add iframe').contents().find('#tinymce')[0].innerHTML;
        data.description = data.description == '\n' || data.description == '<p><br data-mce-bogus="1"></p>' || data.description == '<p><br data-mce-bogus="1"></p><div style="display: none;"></div>' ? '' : data.description;
        data.date_from = date_from;
        data.date_to = date_to;
        var education = this.get('store').createRecord('education', data);
        var self = this;
        var datesAreCorrect = true;
        education.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = 'This is a required field!';
          });
          this.set('errors', errors);
          if (education.get('date_from') == '' || education.get('date_from').includes('De')) {
            this.set('errors.date_from', 'This is a required field!');
            datesAreCorrect = false;
          }
          if (this.get('currently') || education.get('date_to').includes('Defa-De')) {
            education.set('date_to', null);
          }
          if (!this.get('currently') && education.get('date_to') != null) {
            if ((0, _moment.default)(education.get('date_to')).isBefore(education.get('date_from'))) {
              this.set('errors.date_to', 'Your completion date cannot be before your starting date');
              datesAreCorrect = false;
            }
            if (education.get('date_to') == '' || education.get('date_to').includes('De')) {
              this.set('errors.date_to', 'This is a required field!');
              datesAreCorrect = false;
            }
          }
          Ember.run.later(function () {
            window.$('.education-add [class*=validation-error]').each(index => {
              if (window.$('.education-add [class*=validation-error]')[index].innerText != '') {
                window.$('.education-add [class*=validation-error]')[index].parentElement.scrollIntoView();
                return false;
              }
            });
          }, 500);
          if (validations.get('isValid') && datesAreCorrect) {
            education.save().then(function () {
              const initialEducationLength = self.get('initialEducation').length;
              self.set('addNewEducation', false);
              self.get('educations').pushObject(education);
              self.toast.success('Your education has been updated');
              if (initialEducationLength == 0) {
                self.set('bioEdited', self.get('currentUser.talent.completion') + 15);
              }
              self.set('initialEducation', self.get('educations'));
              self.get('currentUser').load();
              self.send('clearProperties');
            });
          }
        });
      },
      updateDate: function (date, type, education, event) {
        education.set('isUpdated', true);
        var value = event.target.value;
        var educationDate;
        if (date == "date_from") {
          educationDate = '';
          if (education.get('date_from')) {
            educationDate = education.get('date_from');
          } else {
            educationDate = (0, _moment.default)().format('YYYY-MM-DD');
          }
          if (type == 'month') {
            education.set('date_from', this.replaceAt(educationDate, 5, value));
          } else {
            education.set('date_from', this.replaceAt(educationDate, 0, value));
          }
          education.set('date_from', this.replaceAt(education.get('date_from'), 8, '01'));
        } else if (date == "date_to") {
          educationDate = education.get('date_to');
          if (type == 'month') {
            education.set('date_to', this.replaceAt(educationDate, 5, value));
          } else {
            education.set('date_to', this.replaceAt(educationDate, 0, value));
          }
          education.set('date_to', this.replaceAt(education.get('date_to'), 8, '01'));
        }
        this.send('updateEducation', education, date, 'date');
      },
      currentDate: function (education, event) {
        if (typeof education.id != 'undefined') {
          if (education && event) {
            education.set('date_to', null);
          } else if (education && !event) {
            education.set('date_to', (0, _moment.default)().format('YYYY-MM-DD'));
          }
          education.set('isUpdated', true);
          this.send('updateEducation', education, 'date_to', 'date');
        } else {
          this.toggleProperty('currently');
          if (this.get('currently')) {
            this.set('date_to', null);
          } else {
            this.set('date_to', (0, _moment.default)().format('YYYY-MM-DD'));
          }
        }
      },
      didAddPlace: function (place) {
        this.send('locationUpdate', this, place);
      },
      didUpdatePlace: function (education, place) {
        if (typeof place.place != 'undefined') {
          this.send('locationUpdate', education, place);
          if (education) {
            this.send('updateEducation', education, place, 'location');
            education.set('location.address', place.place.formatted_address);
          }
        }
      },
      locationUpdate: function (obj, place) {
        var country, country_short, city, state, post_code;
        obj.set('address', place.place.formatted_address);
        if (!place.lng) {
          obj.set('longitude', null);
        } else {
          obj.set('longitude', place.lng.toFixed(8));
        }
        if (!place.lat) {
          obj.set('longitude', null);
        } else {
          obj.set('latitude', place.lat.toFixed(8));
        }
        place.place.address_components.forEach(function (element) {
          var type = element.types[0];
          if (type == 'country') {
            country = element.long_name;
            country_short = element.short_name;
          }
          if (type == 'locality' || type == 'postal_town') {
            city = element.long_name;
          }
          if (type == 'administrative_area_level_1') {
            state = element.long_name;
          }
          if (type == 'postal_code') {
            post_code = element.long_name;
          }
        });
        obj.set('country', country);
        obj.set('country_short', country_short);
        obj.set('city', city);
        obj.set('state', state);
        obj.set('post_code', post_code);
      },
      invalidUserSelection: function () {},
      valueChanged: function (education, key, value) {
        education.set(key, value);
        education.set('isUpdated', true);
      },
      clearProperties() {
        this.setProperties({
          school: "",
          course: "",
          qualification: "",
          grade: "",
          date_from: "",
          date_to: "",
          currently: false,
          description: "",
          country_short: "",
          country: "",
          address: "",
          city: "",
          state: "",
          post_code: "",
          longitude: "",
          latitude: "",
          education_new_address: "",
          errors: {}
        });
      }
    }
  });
});