define("dashboard-talent/models/talent", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    talentresponse: _emberData.default.belongsTo("talentresponse"),
    user: _emberData.default.belongsTo("user"),
    experience: _emberData.default.hasMany("experience"),
    education: _emberData.default.hasMany("education"),
    location: _emberData.default.attr(),
    skills: _emberData.default.attr(),
    following: _emberData.default.attr(),
    interests: _emberData.default.attr(),
    introduction: _emberData.default.attr(),
    active: _emberData.default.attr(),
    current_company: _emberData.default.attr(),
    current_title: _emberData.default.attr(),
    completion: _emberData.default.attr(),
    impressions: _emberData.default.attr(),
    looking: _emberData.default.attr(),
    jobs_applied_no: _emberData.default.attr(),
    updated_at: _emberData.default.attr()
  });
});