define('dashboard-talent/models/education', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const EducationValidations = (0, _emberCpValidations.buildValidations)({
    school: (0, _emberCpValidations.validator)('presence', true),
    qualification: (0, _emberCpValidations.validator)('presence', true),
    grade: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'This is a required field!'
      }), (0, _emberCpValidations.validator)('length', {
        max: 20,
        message: 'Ensure this field has no more than 20 characters.'
      })]
    },
    description: (0, _emberCpValidations.validator)('presence', true),
    date_from: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _emberData.default.Model.extend(EducationValidations, {
    talent: _emberData.default.belongsTo("talent"),
    school: _emberData.default.attr("string"),
    qualification: _emberData.default.attr("string"),
    location: _emberData.default.attr(),
    grade: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    date_from: _emberData.default.attr(),
    date_to: _emberData.default.attr(),
    address: _emberData.default.attr(),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    post_code: _emberData.default.attr('string'),
    longitude: _emberData.default.attr("string"),
    latitude: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    country_short: _emberData.default.attr("string"),
    errors: _emberData.default.attr()
  });
});