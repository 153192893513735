define('dashboard-talent/components/talent/edit-experience', ['exports', 'dashboard-talent/components/loader-component', 'moment'], function (exports, _loaderComponent, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderComponent.default.extend({
    modelType: 'experience',
    experiences: Ember.computed.alias('objects'),
    tagName: 'div',
    years: null,
    months: null,
    errors: null,
    autoLoad: true,
    address: '',
    longitude: '',
    latitude: '',
    country: '',
    country_short: '',
    city: '',
    state: '',
    post_code: '',
    addNewExperience: false,
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    initialExperience: null,
    options: null,
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        statusbar: false,
        content_style: ".mce-content-body {font-size:0.9rem;font-family:Roboto, 'Helvetica Neue', sans-serif;}",
        plugins: ['autolink lists link charmap anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime nonbreaking save contextmenu directionality', 'template paste textpattern'],
        toolbar1: 'bold | bullist | link ',
        contextmenu: 'link'
      });

      // Initialize the experiences
      this.get('store').unloadAll(this.get('modelType'));
      this.set('loading', false);
      this.set('loaded', false);
      this.set("objects", []);
      this.send('load');

      var today = new Date().getFullYear();
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var years = [];
      for (var i = today; i >= 1965; i--) {
        years.push(i);
      }
      this.set('months', months);
      this.set('years', years);
      this.send('clearProperties');
    },
    didRender() {
      this._super(...arguments);
      this.set('initialExperience', this.get('experiences'));
      if (this.get('bioEdited')) {
        this.set('currentUser.talent.completion', this.get('bioEdited'));
      }
      window.$('.experience-list .mce-btn:not(.mce-disabled)').on('click touchstart', function () {
        window.$(this).closest('.clearfix.col-sm-12').find('.btn--edit').attr('disabled', false);
      });
    },
    replaceAt(string, index, replacement) {
      return `${string.substr(0, index)}${replacement}${string.substr(index + replacement.length)}`;
    },
    actions: {
      updateExperience: function (experience, field, type) {
        if (type == undefined) {
          experience.set('showCompanyError', true);
          experience.set('showTitleError', true);
        }
        experience.set('isUpdated', true);
        let prefix = 'location.';
        if (type == 'location') {
          experience.set('location', {});
        }
        let locationKeys = ['address', 'city', 'state', 'post_code', 'longitude', 'latitude', 'country', 'country_short'];
        locationKeys.forEach(key => {
          if (type == 'location') {
            experience.set(`${prefix}${key}`, experience.get(key));
          } else {
            experience.set(key, experience.get(`${prefix}${key}`));
          }
        });
        var experienceIndex = this.get('experiences').indexOf(experience);
        var description = window.$(`#experience${experienceIndex} iframe`).contents().find('#tinymce')[0].innerHTML;
        description = description == '\n' || description == '<p><br data-mce-bogus="1"></p>' ? '' : description;
        experience.set('description', description);
        var self = this;
        delete experience.errors;
        var datesAreCorrect = true;
        experience.validate().then(({ validations }) => {
          experience.set('errors', {});
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = 'This is a required field!';
          });
          experience.set('errors', errors);
          if ((0, _moment.default)(experience.get('date_from')).isAfter((0, _moment.default)().format('YYYY-MM-DD'))) {
            experience.set('errors.date_from', 'Your starting date cannot be date in future');
            datesAreCorrect = false;
          }
          if (experience.get('date_to')) {
            if ((0, _moment.default)(experience.get('date_to')).isBefore(experience.get('date_from'))) {
              experience.set('errors.date_to', 'Your completion date cannot be before your starting date');
              datesAreCorrect = false;
            }
            if ((0, _moment.default)(experience.get('date_to')).isAfter((0, _moment.default)().format('YYYY-MM-DD'))) {
              experience.set('errors.date_to', 'Your completion date cannot be date in future');
              datesAreCorrect = false;
            }
            if (experience.get('date_to') == '' || experience.get('date_to').includes('De')) {
              experience.set('errors.date_to', 'This is a required field!');
              datesAreCorrect = false;
            }
          }
          if (experience.get('date_from') == '' || experience.get('date_from').includes('De')) {
            experience.set('errors.date_from', 'This is a required field!');
            datesAreCorrect = false;
          }
          Ember.run.later(function () {
            window.$(`#experience${experienceIndex} [class*=validation-error]`).each(index => {
              if (window.$(`#experience${experienceIndex} [class*=validation-error]`)[index].innerText != '') {
                window.$(`#experience${experienceIndex} [class*=validation-error]`)[index].parentElement.scrollIntoView();
                return false;
              }
            });
          }, 500);
          if (validations.get('isValid') && datesAreCorrect) {
            if (type != 'date' && type != 'location' && type != 'description') {
              experience.save().then(function () {
                self.toast.success('Your experience has been updated');
                experience.set('isUpdated', undefined);
                self.get('currentUser').load();
              });
            }
          }
        });
      },
      cancelNewExperience: function () {
        window.$('.add-new-experience').find('form')[0].reset();
        this.send('clearProperties');
        this.set('addNewExperience', false);
      },
      removeExperience: function () {
        var experience = this.get('selectedObj');
        experience.destroyRecord();
        this.get('experiences').removeObject(experience);
        this.toast.success('Experience removed');
        if (this.get('experiences').length == 0) {
          this.set('bioEdited', this.get('currentUser.talent.completion') - 20);
        }
        this.set('initialExperience', this.get('experiences'));
        this.get('currentUser').load();
      },
      removeConfirm: function (experience) {
        this.set('selectedObj', experience);
      },
      saveExperience: function () {
        var selects = window.$('select[id^=experience_date_]');
        var date = {};
        selects.each(function () {
          var name = window.$(this).attr('name');
          var value = window.$(this).val();
          date[name] = value;
        });
        var date_from = `${date['experience_date_from_year']}-${date['experience_date_from_month']}-01`;
        var date_to = `${date['experience_date_to_year']}-${date['experience_date_to_month']}-01`;
        var data = this.getProperties('company', 'title', 'description', 'address', 'city', 'state', 'post_code', 'longitude', 'latitude', 'country', 'country_short');
        data.description = window.$('.add-new-experience iframe').contents().find('#tinymce')[0].innerHTML;
        data.description = data.description == '\n' || data.description == '<p><br data-mce-bogus="1"></p>' || data.description == '<p><br data-mce-bogus="1"></p><div style="display: none;"></div>' ? '' : data.description;
        data.date_from = date_from;
        data.date_to = date_to;
        var experience = this.get('store').createRecord('experience', data);
        var self = this;
        var datesAreCorrect = true;
        experience.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = 'This is a required field!';
          });
          this.set('errors', errors);
          if ((0, _moment.default)(experience.get('date_from')).isAfter((0, _moment.default)().format('YYYY-MM-DD'))) {
            this.set('errors.date_from', 'Your starting date cannot be date in future');
            datesAreCorrect = false;
          }
          if (this.get('currently') || experience.get('date_to').includes('Defa-De')) {
            experience.set('date_to', null);
          }
          if (!this.get('currently') && experience.get('date_to') != null) {
            if ((0, _moment.default)(experience.get('date_to')).isBefore(experience.get('date_from'))) {
              this.set('errors.date_to', 'Your completion date cannot be before your starting date');
              datesAreCorrect = false;
            }
            if ((0, _moment.default)(experience.get('date_to')).isAfter((0, _moment.default)().format('YYYY-MM-DD'))) {
              this.set('errors.date_to', 'Your completion date cannot be date in future');
              datesAreCorrect = false;
            }
            if (experience.get('date_to') == '' || experience.get('date_to').includes('De')) {
              this.set('errors.date_to', 'This is a required field!');
              datesAreCorrect = false;
            }
          }
          if (experience.get('date_from') == '' || experience.get('date_from').includes('De')) {
            this.set('errors.date_from', 'This is a required field!');
            datesAreCorrect = false;
          }
          Ember.run.later(function () {
            window.$('.add-new-experience [class*=validation-error]').each(index => {
              if (window.$('.add-new-experience [class*=validation-error]')[index].innerText != '') {
                window.$('.add-new-experience [class*=validation-error]')[index].parentElement.scrollIntoView();
                return false;
              }
            });
          }, 500);
          if (validations.get('isValid') && datesAreCorrect) {
            experience.save().then(function () {
              const initialExperienceLength = self.get('initialExperience').length;
              self.set('addNewExperience', false);
              self.get('experiences').pushObject(experience);
              self.toast.success('Your experience has been updated');
              if (initialExperienceLength == 0) {
                self.set('bioEdited', self.get('currentUser.talent.completion') + 20);
              }
              self.set('initialExperience', self.get('experiences'));
              self.get('currentUser').load();
              self.send('clearProperties');
            });
          }
        });
      },
      updateDate: function (date, type, experience, event) {
        experience.set('isUpdated', true);
        var value = event.target.value;
        var experienceDate;
        if (date == "date_from") {
          experienceDate = '';
          if (experience.get('date_from')) {
            experienceDate = experience.get('date_from');
          } else {
            experienceDate = (0, _moment.default)().format('YYYY-MM-DD');
          }
          if (type == 'month') {
            experience.set('date_from', this.replaceAt(experienceDate, 5, value));
          } else {
            experience.set('date_from', this.replaceAt(experienceDate, 0, value));
          }
          experience.set('date_from', this.replaceAt(experience.get('date_from'), 8, '01'));
        } else if (date == "date_to") {
          experienceDate = experience.get('date_to');
          if (type == 'month') {
            experience.set('date_to', this.replaceAt(experienceDate, 5, value));
          } else {
            experience.set('date_to', this.replaceAt(experienceDate, 0, value));
          }
          experience.set('date_to', this.replaceAt(experience.get('date_to'), 8, '01'));
        }
        this.send('updateExperience', experience, date, 'date');
      },
      currentDate: function (experience, event) {
        if (typeof experience.id != 'undefined') {
          if (experience && event) {
            experience.set('date_to', null);
          } else if (experience && !event) {
            experience.set('date_to', (0, _moment.default)().format('YYYY-MM-DD'));
          }
          experience.set('isUpdated', true);
          this.send('updateExperience', experience, 'date_to', 'date');
        } else {
          this.toggleProperty('currently');
          if (this.get('currently')) {
            this.set('date_to', null);
          } else {
            this.set('date_to', (0, _moment.default)().format('YYYY-MM-DD'));
          }
        }
      },
      didAddPlace: function (place) {
        this.send('locationUpdate', this, place);
      },
      didUpdatePlace: function (experience, place) {
        if (typeof place.place != 'undefined') {
          this.send('locationUpdate', experience, place);
          if (experience) {
            this.send('updateExperience', experience, place, 'location');
            experience.set('location.address', place.place.formatted_address);
          }
        }
      },
      locationUpdate: function (obj, place) {
        var country, country_short, city, state, post_code;
        obj.set('address', place.place.formatted_address);
        if (!place.lng) {
          obj.set('longitude', null);
        } else {
          obj.set('longitude', place.lng.toFixed(8));
        }
        if (!place.lat) {
          obj.set('longitude', null);
        } else {
          obj.set('latitude', place.lat.toFixed(8));
        }
        place.place.address_components.forEach(function (element) {
          var type = element.types[0];
          if (type == 'country') {
            country = element.long_name;
            country_short = element.short_name;
          }
          if (type == 'locality' || type == 'postal_town') {
            city = element.long_name;
          }
          if (type == 'administrative_area_level_1') {
            state = element.long_name;
          }
          if (type == 'postal_code') {
            post_code = element.long_name;
          }
        });
        obj.set('country', country);
        obj.set('country_short', country_short);
        obj.set('city', city);
        obj.set('state', state);
        obj.set('post_code', post_code);
      },
      invalidUserSelection: function () {},
      valueChanged: function (experience, key, value) {
        experience.set(key, value);
        experience.set('isUpdated', true);
      },
      clearProperties() {
        this.setProperties({
          company: "",
          title: "",
          date_from: "",
          date_to: "",
          currently: false,
          description: "",
          country_short: "",
          country: "",
          address: "",
          city: "",
          state: "",
          post_code: "",
          longitude: "",
          latitude: "",
          experience_new_address: "",
          errors: {}
        });
      }
    }
  });
});