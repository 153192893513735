define('dashboard-talent/components/talent/edit-skills', ['exports', 'dashboard-talent/components/base-component', 'dashboard-talent/config/environment', 'ember-concurrency', 'fetch'], function (exports, _baseComponent, _environment, _emberConcurrency, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    skills: null,
    addNewSkill: false,
    initialSkills: null,
    init() {
      this._super(...arguments);
      this.set('skills', []);
      this.send('load');
    },
    didRender() {
      this._super(...arguments);
      if (this.get('bioEdited')) {
        this.set('currentUser.talent.completion', this.get('bioEdited'));
      }
    },
    actions: {
      load() {
        this.get('store').query('skill', {}).then(response => {
          this.set('selectedSkills', []);
          var self = this;
          response.forEach(function (object) {
            self.get('selectedSkills').pushObject(object.get('name'));
          });
          this.set('initialSkills', this.get('selectedSkills'));
        });
      },
      removeSkill: function (skill) {
        skill.destroyRecord();
      },
      createSkillOnEnter(select, e) {
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          let selected = this.get('selectedSkills');
          if (!selected.includes(select.searchText)) {
            select.actions.choose(select.searchText);
            this.get('currentUser').load();
          }
        }
      },
      updateSkills(options) {
        this.set('selectedSkills', options);
        var skills = options.join(',');
        var d = new Date();
        var id = d.getTime();
        var data = {
          id: id,
          skills: skills
        };
        this.get('store').createRecord('skills', data).save().then(() => {
          this.get('currentUser').load();
        }).catch(xhr => {
          this.set('error', xhr.errors);
        });
        if (this.get('selectedSkills').length == 0) {
          this.set('bioEdited', this.get('currentUser.talent.completion') - 10);
        } else if (this.get('initialSkills').length == 0) {
          this.set('bioEdited', this.get('currentUser.talent.completion') + 10);
        }
        this.set('initialSkills', this.get('selectedSkills'));
      }
    },
    searchSkills: (0, _emberConcurrency.task)(function* (term) {
      var url = _environment.default.APP.API_HOST + '/skills?search=' + term;
      yield (0, _emberConcurrency.timeout)(300);
      return (0, _fetch.default)(url).then(resp => resp.json()).then(json => {
        var skills = [term];
        // BUG: adds 'ROOT' to skills due to key in json returning a ROOT object
        for (var key in json) {
          var item = json[key];
          if (item["name"] != term && item["name"] != 'ROOT' && item["name"].length > 1) {
            skills.pushObject(item["name"]);
          }
        }
        return skills;
      });
    })
  });
});