define('dashboard-talent/components/settings/control-feed', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    onControlFeedOpened: Ember.observer('isControlFeedOpened', function () {
      this.set('errors', {});
      this.set('selectedJobType', null);
      this.set('selectedJobLevel', null);
      window.$('select[name=jobType]').val('');
      window.$('select[name=jobLevel]').val('');
      window.$('select[name=location]').val('');
    }),
    init() {
      this._super(...arguments);
      this.set('errors', {});
    },
    actions: {
      addInterest: function (id, level) {
        if (id && level) {
          this.get('store').query('interest', {}).then(response => {
            var interests = response.toArray();
            let exists = interests.filter(interest => interest.get('job_level') == level && interest.get('job_type.id') == id);
            if (exists.length) {
              this.set('errors.interest', 'The interest added already exists.');
            } else {
              this.set('errors.interest', '');
              var newInterest = this.get('store').createRecord('interest', {
                'job_type_id': id,
                'job_level': level
              });
              newInterest.save().then(() => {
                this.set('interestAdded', !this.get('interestAdded'));
                this.set('selectedJobType', null);
                this.set('selectedJobLevel', null);
                window.$('select[name=jobType]').val('');
                window.$('select[name=jobLevel]').val('');
              });
            }
          });
        } else {
          this.set('errors.interest', 'Please select a job type and job level.');
        }
      }
    }
  });
});