define("dashboard-talent/models/job", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        //company: DS.belongsTo('company'),
        title: _emberData.default.attr("string"),
        description: _emberData.default.attr("string"),
        contract: _emberData.default.attr("string"),
        level: _emberData.default.attr("string"),
        address: _emberData.default.attr("string"),
        country: _emberData.default.attr("string"),
        country_short: _emberData.default.attr("string"),
        lat: _emberData.default.attr("string"),
        lng: _emberData.default.attr("string"),
        content: _emberData.default.attr(),
        type: _emberData.default.attr(),
        //content: DS.hasMany("content"),
        location: _emberData.default.attr(),
        job_type_id: _emberData.default.attr("number"),
        salary_from: _emberData.default.attr("number"),
        salary_to: _emberData.default.attr("number"),
        salary_disclosed: _emberData.default.attr("number"),
        status: _emberData.default.attr("string"),
        views: _emberData.default.attr("number"),
        impressions: _emberData.default.attr("number"),
        applications: _emberData.default.attr("number"),
        published_at: _emberData.default.attr("date"),
        updated_at: _emberData.default.attr("date"),
        created_at: _emberData.default.attr("date")
    });
});