define('dashboard-talent/models/conversation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    initiator: _emberData.default.attr(),
    participants: _emberData.default.attr(),
    name: _emberData.default.attr(),
    messages_no: _emberData.default.attr(),
    last_message: _emberData.default.attr(),
    last_message_at: _emberData.default.attr(),
    last_message_sender: _emberData.default.attr(),
    unread: _emberData.default.attr('number'),
    // this is not an actual attribute only used when creating
    // a conversation on our side, it is not returned by the API!!
    participantId: _emberData.default.attr('number'),
    participant: null,
    title(currentUserId) {
      var participants = this.get('participants');
      // in a one to one convo we'll use the other persons full name
      if (participants.length == 2) {
        for (var key1 in participants) {
          var participant1 = participants[key1];
          if (participant1.id != currentUserId) {
            this.set('participant', participant1);
            return `${participant1.first_name} ${participant1.last_name}`;
          }
        }
      }
      // for group convos we'll use everyone elses first names
      var title = '';
      for (var key2 in participants) {
        var participant2 = participants[key2];
        if (participant2.id != currentUserId) {
          title += `, ${participant2.first_name}`;
        }
      }
      // remove first ,<space>
      title = title.substring(2, title.length);
      return title;
    }
  });
});