define('dashboard-talent/components/common/feed-interest', ['exports', 'dashboard-talent/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    checked: true,
    paginated: false,
    type: Ember.computed('modelType', function () {
      if (this.get('modelType') == 'company') {
        return 'company';
      } else {
        return 'topic';
      }
    }),
    onInterestAdded: Ember.observer('interestAdded', function () {
      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        hasMore: true,
        objects: []
      });
      this.send('loadMore');
    }),
    onLocationInterestAdded: Ember.observer('locationInterestAdded', function () {
      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        hasMore: true,
        objects: []
      });
      this.get('store').unloadAll('location-interest');
      this.send('loadMore');
    }),
    params: Ember.computed('cursor', function () {
      var params = this._super();
      if (this.get('title')) {
        params['paginated'] = this.get('paginated');
        params['type'] = this.get('type');
      }
      return params;
    }),
    actions: {
      removeInterest: function (interest) {
        this.get('objects').removeObject(interest);
        if (this.get('modelType') == 'location-interest') {
          this.set('locationInterestRemoved', !this.get('locationInterestRemoved'));
        }
        interest.destroyRecord();
      }
    }
  });
});