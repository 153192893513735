define('dashboard-talent/components/common/preview-bio', ['exports', 'dashboard-talent/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    state: "Bio",
    completion: '',
    onPreviewTalentBio: Ember.observer('previewTalentBio', function () {
      this.send('load');
    }),
    onBioEdited: Ember.observer('bioEdited', function () {
      this.send('updateCompletion');
    }),
    placeholderAvatar: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + 'images/site/talent_placeholder_avatar.png');
    }),
    bioCompletion: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(`width: ${this.get('currentUser.talent.completion')}%`);
    }),
    init() {
      this._super(...arguments);
      this.send('load');
      this.set('completion', this.get('currentUser.talent.completion'));
      var self = this;
      this.get('store').query('submitted-application', {}).then(response => {
        if (response.get('length') > 0 || self.get('currentUser.isOrganicTalent') || self.get('currentUser.isCollectTalent')) {
          self.set('state', 'Bio');
        } else {
          self.set('state', 'Application');
        }
      });
    },
    actions: {
      load() {
        this.get('store').query('skill', {}).then(response => {
          this.set('selectedSkills', []);
          var self = this;
          response.forEach(function (object) {
            self.get('selectedSkills').pushObject(object.get('name'));
          });
        });
        this.get('store').query('education', {}).then(response => {
          this.set('education', []);
          var self = this;
          response.forEach(function (object) {
            self.get('education').pushObject(object);
          });
        });
        this.get('store').query('experience', {}).then(response => {
          this.set('experience', []);
          var self = this;
          response.forEach(function (object) {
            self.get('experience').pushObject(object);
          });
        });
      },
      updateCompletion: function () {
        this.set('currentUser.talent.completion', this.get('bioEdited'));
        this.set('bioCompletion', new Ember.String.htmlSafe(`width: ${this.get('bioEdited')}%`));
        this.set('completion', this.get('currentUser.talent.completion'));
      }
    }
  });
});