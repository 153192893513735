define('dashboard-talent/models/bio', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const BioValidations = (0, _emberCpValidations.buildValidations)({
        first_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            max: 255,
            message: 'Ensure this field has no more than 255 characters.'
        })],
        last_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            max: 255,
            message: 'Ensure this field has no more than 255 characters.'
        })],
        email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            type: 'email',
            message: 'Please enter a valid email address!'
        })],
        phone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
            regex: /^\d+$/,
            message: 'Please enter a valid phone number!'
        })],
        current_title: (0, _emberCpValidations.validator)('presence', true),
        current_company: (0, _emberCpValidations.validator)('presence', true),
        address: (0, _emberCpValidations.validator)('presence', true),
        country: (0, _emberCpValidations.validator)('presence', true),
        country_short: (0, _emberCpValidations.validator)('presence', true),
        country_code: (0, _emberCpValidations.validator)('presence', true)
    });

    exports.default = _emberData.default.Model.extend(BioValidations, {
        completion: _emberData.default.attr('number'),
        first_name: _emberData.default.attr('string'),
        last_name: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        current_title: _emberData.default.attr('string'),
        current_company: _emberData.default.attr('string'),
        address: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        state: _emberData.default.attr('string'),
        post_code: _emberData.default.attr('string'),
        longitude: _emberData.default.attr('string'),
        latitude: _emberData.default.attr('string'),
        country: _emberData.default.attr('string'),
        country_short: _emberData.default.attr('string'),
        country_code: _emberData.default.attr('string')
    });
});