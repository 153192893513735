define('dashboard-talent/helpers/slice', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.slice = slice;
  function slice(params /*, hash*/) {
    var string = params[0];
    var number = params[1];
    return string.slice(number);
  }

  exports.default = Ember.Helper.helper(slice);
});