define('dashboard-talent/components/common/front-head', ['exports', 'dashboard-talent/components/base-component', 'dashboard-talent/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    router: Ember.inject.service(),
    windowscroll: Ember.inject.service(),
    store: Ember.inject.service(),
    active: false,
    search: "https://" + _environment.default.APP.DOMAIN + "/search?search=",
    isIndexRoute: Ember.computed(function () {
      return this.get('router.currentRouteName') == 'index' ? 'active' : '';
    }),
    isEditRoute: Ember.computed(function () {
      return this.get('router.currentRouteName') == 'edit' ? 'active' : '';
    }),
    isApplicationsRoute: Ember.computed(function () {
      return this.get('router.currentRouteName') == 'applications' ? 'active' : '';
    }),
    isSettingsRoute: Ember.computed(function () {
      return this.get('router.currentRouteName') == 'settings' ? 'active' : '';
    }),
    logoDark: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_dark.png");
    }),
    logoLight: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_light.png");
    }),
    onApplicationSubmitted: Ember.observer('submittedApplication.id', function () {
      this.get('store').queryRecord('notification', {}).then(response => {
        this.set('pending', response.get('pending_applications'));
      });
    }),
    init() {
      this._super(...arguments);
      this.get('store').queryRecord('notification', {}).then(response => {
        this.set('notifications', response);
        this.set('pending', response.get('pending_applications'));
      });
      this.get('windowscroll').on('scroll', () => {
        if (this.get('windowscroll.scrollTop') > 366) {
          window.$('.front-head #menu1').addClass('pos-fixed');
        } else {
          window.$('.front-head #menu1').removeClass('pos-fixed');
        }
      });
    },
    actions: {
      invalidateSession: function () {
        return this.get('session').invalidate();
      }
    }
  });
});