define('dashboard-talent/components/common/collect-talent-modal', ['exports', 'dashboard-talent/components/pagination-component', 'dashboard-talent/config/environment'], function (exports, _paginationComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    currentUser: Ember.inject.service(),
    autoLoad: true,
    modelType: 'company',
    page_size: 1,
    latestFollowedCompany: null,
    searchJobsUrl: 'https://' + _environment.default.APP.DOMAIN + '/search',
    actions: {
      loaded() {
        var self = this;
        var completion = this.get('currentUser.talent.completion');
        if (completion < 60 && this.get('currentUser.isCollectTalent') && this.get('currentUser.showModal')) {
          window.$('#collectModal').modal();

          this.get('store').queryRecord('tour', {}).then(response => {
            self.set('page_tour', response);
            if (self.get('page_tour.guided_tour') == false) {
              self.get('page_tour').set('guided_tour', true);
              self.get('page_tour').save();
            }
          });
        }
        this.set('latestFollowedCompany', this.get('objects.firstObject'));
      }
    }
  });
});